import axios from "axios";
const { BACKEND_URL, DEBUG } = process.env;

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
const Api = axios.create({
  baseURL: BACKEND_URL,
  timeout: 0,
  withCredentials: true,
  mode: "no-cors",
  crossDomain: true,
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Allow-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "x-requested-with",
  },
});

const getSurveyEmployeeScores = async (linkId, type, workflowId, currentPage) => {
  if (!linkId) return;
  return !DEBUG && Api.get(`v1/link/${linkId}?type=${type}&workflowId=${workflowId}&page=${currentPage}`);
};

const getWorkflows = async (linkId) => {
   if (!linkId) return;
  return !DEBUG && Api.get(`v1/link/${linkId}/get-workflows`);
}

const saveEmployeeReviewScore = async (linkId, data) => {
  if (!linkId) return;
  return !DEBUG && Api.patch(`v1/link/${linkId}`, data);
};

const updateStage = async (surveyReviewId, data) => {
  if (!surveyReviewId) return;
  return !DEBUG && Api.patch(`v1/link/update-stage/${surveyReviewId}`, data);
};

const saveAdditionalInformation = async (surveyReviewId, data) => {
  if (!surveyReviewId) return;
  return !DEBUG && Api.patch(`v1/link/save-additional-informations/${surveyReviewId}`, data);
};

const getEmployeeInfo = async (linkId) => {
  if (!linkId) return;
  return !DEBUG && Api.get(`v1/link/${linkId}/review-employee`);
};


const Http = {
  getSurveyEmployeeScores,
  saveEmployeeReviewScore,
  getWorkflows,
  updateStage,
  getEmployeeInfo,
  saveAdditionalInformation
};

export default Http;
